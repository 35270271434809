import __vite__cjsImport0__popperjs_core_dist_umd_popper_min from "/node_modules/.vite/deps/@popperjs_core_dist_umd_popper__min.js?v=c8438652"; const Popper = __vite__cjsImport0__popperjs_core_dist_umd_popper_min.__esModule ? __vite__cjsImport0__popperjs_core_dist_umd_popper_min.default : __vite__cjsImport0__popperjs_core_dist_umd_popper_min;

// Required to enable animations on dropdowns/tooltips/popovers
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

try {
  window.Popper = Popper;
} catch (e) {}

export { Popper };
